//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import { getStoryLinkPath } from '~/modules/storyblok/helpers/getStoryLinkPath';

export default defineComponent({
  props: ['blok'],
  setup() {
    return { getStoryLinkPath };
  },
});
